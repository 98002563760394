.appBody {
  margin-top: 4%;
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: 1%;
}

.experienceCardsWrapper {
  display: flex;
  justify-content: space-evenly;
  flex-flow: row wrap;

  margin: 4% 8%;
}

.experienceCard {
  margin: 2%;
}

.vanta {
  background-color: var(--secondaryBackground);
  background-repeat: no-repeat;
  width: 100%;
  top: -80px;
  bottom: -80px;
  position: fixed;
  z-index: -1;
}
