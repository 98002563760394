.cardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: var(--borderRadius);
  background: var(--primaryBackground);

  height: 400px;
  width: 400px;

  cursor: pointer;

  transition: background 0.2s ease-in-out;

  img {
    transition: filter 0.3s ease-in-out;
  }
}

.cardWrapper:hover {
  background: var(--secondaryBackground);

  img {
    filter: unset;
  }
}

.cardImageWrapper {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 8%;

  img {
    width: 100%;
    height: auto;

    filter: grayscale(100%);
  }
}

.cardContent {
  margin: 4%;
  text-align: center;

  .cardTitle {
    font-size: 24px;
    font-weight: 600;
  }
  .cardDate {
    font-size: 20px;
  }
}
