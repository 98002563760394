.footerWrapper {
  text-align: center;

  color: white;

  a {
    text-decoration: unset;
  }

  a:hover {
    text-decoration: underline;
  }
}
