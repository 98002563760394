.pageWrapper {
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: var(--primaryBackground);
  z-index: 2;
}

.modalWrapper {
  position: absolute;
  margin: auto;

  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;

  max-width: 960px;
  max-height: 70%;

  background-color: #446e7d;
  border-radius: 4px;
  z-index: 3;
}

.modalContent {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.modalTop {
  display: flex;
  justify-content: space-between;
}

.closeModal {
  position: absolute;
  right: 10px;
  padding: 15px;

  cursor: pointer;

  font-weight: 900;
  font-size: 24px;
}

.title {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;

  width: 90%;

  font-size: 26pt;

  a {
    font-weight: 600;
    text-decoration: unset;
  }

  a:hover {
    text-decoration: underline;
  }
}

.subtitle {
  font-size: 20pt;
  font-weight: 500;

  padding: 10px 0;
}

.modalMiddle {
  height: 60%;

  display: flex;
  justify-content: center;

  margin-top: 8%;
}

.modalImage {
  height: 80%;
  width: auto;

  overflow: hidden;

  filter: drop-shadow(0px 10px 6px #3d545d);
}

@media (max-width: 800px) {
  .title {
    font-size: 20pt;
  }

  .modalWrapper {
    max-height: 50%;

    .modalMiddle {
      height: 40%;
    }

    .modalBottom {
      height: 35%;
    }
  }
}

.modalBottom {
  display: flex;
  flex-direction: column;

  height: 20%;

  border-radius: var(--borderRadius);

  background-color: #0d5873;

  margin-left: 5%;
  margin-right: 5%;

  > div {
    margin-left: 5%;
    margin-right: 5%;
  }
}
