.headerWrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  background: var(--primaryBackground);
  border-radius: var(--borderRadius);

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 30%;
  min-width: 400px;

  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 1%;

  > * + * {
    margin-top: 12px;
  }
}

.avatar {
  border-radius: 50%;
}

.title,
.subtitle,
.preface {
  text-align: center;
}

.title {
  font-size: 26pt;
  font-weight: 600;
}

.subtitle {
  font-size: 20pt;
  font-weight: 500;

  padding-bottom: 2%;
}

.preface,
.email {
  font-size: 15pt;
}

.preface {
  padding-bottom: 2%;
}

.email {
  padding-bottom: 8%;
}

.contactInfo {
  a {
    text-decoration: unset;
  }

  a:hover {
    text-decoration: underline;
  }
}

.lastPlayed {
  display: flex;
  align-items: center;

  > div {
    text-align: center;
  }

  > img {
    padding-right: 6px;
  }
}
